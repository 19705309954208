@import 'styles/fonts';

html
{
    background-color: #363736;
}

body
{
    margin:           0;
    font-family:      $fontCorporateSBQ;
    font-weight:      normal;
    cursor:           none !important;
    color:            white;
    background-color: #363736;

    &:before
    {
        content:               "";
        position:              absolute;
        height:                100%;
        width:                 100%;
        background:            rgba(0, 0, 0, .65) url(./images/coming-soon-house.jpg) no-repeat center;
        background-size:       cover;
        background-blend-mode: darken;
        filter:                grayscale(100%);
    }
}

*:hover
{
    cursor: none !important;
}

a
{
    text-decoration: none;
    color:           white;
}
