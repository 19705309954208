$fontNotoSans:     'Noto Sans Javanese';
$fontCorporateSBQ: 'Corporate S BQ';

@font-face
{
    font-family:  $fontCorporateSBQ;
    src:          url('../fonts/dehinted-CorporateSBQ-Regular.woff2') format('woff2'),
                  url('../fonts/dehinted-CorporateSBQ-Regular.woff') format('woff'),
                  url('../fonts/dehinted-CorporateSBQ-Regular.ttf') format('truetype');
    font-weight:  normal;
    font-style:   normal;
    font-display: swap;
}

/* noto-sans-javanese-regular - latin */
@font-face
{
    font-family: $fontNotoSans;
    font-style:  normal;
    font-weight: 400;
    src:         url('../fonts/noto-sans-javanese-v19-latin-regular.eot'); /* IE9 Compat Modes */
    src:         local(''),
                 url('../fonts/noto-sans-javanese-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
                 url('../fonts/noto-sans-javanese-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
                 url('../fonts/noto-sans-javanese-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
                 url('../fonts/noto-sans-javanese-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
                 url('../fonts/noto-sans-javanese-v19-latin-regular.svg#NotoSansJavanese') format('svg'); /* Legacy iOS */
}

/* noto-sans-javanese-500 - latin */
@font-face
{
    font-family: $fontNotoSans;
    font-style:  normal;
    font-weight: 500;
    src:         url('../fonts/noto-sans-javanese-v19-latin-500.eot'); /* IE9 Compat Modes */
    src:         local(''),
                 url('../fonts/noto-sans-javanese-v19-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
                 url('../fonts/noto-sans-javanese-v19-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
                 url('../fonts/noto-sans-javanese-v19-latin-500.woff') format('woff'), /* Modern Browsers */
                 url('../fonts/noto-sans-javanese-v19-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
                 url('../fonts/noto-sans-javanese-v19-latin-500.svg#NotoSansJavanese') format('svg'); /* Legacy iOS */
}

/* noto-sans-javanese-600 - latin */
@font-face
{
    font-family: $fontNotoSans;
    font-style:  normal;
    font-weight: 600;
    src:         url('../fonts/noto-sans-javanese-v19-latin-600.eot'); /* IE9 Compat Modes */
    src:         local(''),
                 url('../fonts/noto-sans-javanese-v19-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
                 url('../fonts/noto-sans-javanese-v19-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
                 url('../fonts/noto-sans-javanese-v19-latin-600.woff') format('woff'), /* Modern Browsers */
                 url('../fonts/noto-sans-javanese-v19-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
                 url('../fonts/noto-sans-javanese-v19-latin-600.svg#NotoSansJavanese') format('svg'); /* Legacy iOS */
}

/* noto-sans-javanese-700 - latin */
@font-face
{
    font-family: $fontNotoSans;
    font-style:  normal;
    font-weight: 700;
    src:         url('../fonts/noto-sans-javanese-v19-latin-700.eot'); /* IE9 Compat Modes */
    src:         local(''),
                 url('../fonts/noto-sans-javanese-v19-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
                 url('../fonts/noto-sans-javanese-v19-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
                 url('../fonts/noto-sans-javanese-v19-latin-700.woff') format('woff'), /* Modern Browsers */
                 url('../fonts/noto-sans-javanese-v19-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
                 url('../fonts/noto-sans-javanese-v19-latin-700.svg#NotoSansJavanese') format('svg'); /* Legacy iOS */
}
