.cursor
{
    $size:            30px;

    position:         fixed;
    top:              50%;
    left:             50%;
    width:            $size;
    height:           $size;
    background-color: white;
    border-radius:    100%;
    transform:        translate(-50%, -50%);
    z-index:          999;
    pointer-events:   none;
}
