h1
{
    font-weight: normal;
    margin:      0 0 10px;
    line-height: 35px;

    b
    {
        font-weight: 600;
    }
}
