.comingSoonContainer
{
    display:         flex;
    top:             0;
    bottom:          0;
    right:           0;
    left:            0;
    margin:          auto;
    flex-direction:  column;
    justify-content: center;
    align-items:     center;
    position:        absolute;

    .centeredContainer
    {

    }
}
